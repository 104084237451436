import { Utils } from '../services/utils';
import { IContact } from './IContact';

export interface IShutterQuote {
  id: string;
  contact: IContact;
  notes: string;
  template: string;
  shutters: IShutter[];
}

export class ShutterQuote implements IShutterQuote {

  id: string;
  contact: IContact;
  notes: string;
  template: string;
  shutters: IShutter[];

  /**
   *
   */
  constructor() {
    this.id = Utils.newGuid();
    this.shutters = [];

  }
}

export interface IShutter {
  id: string;
  location: string;
  width: string;
  height: string;
  tilt: string;
  style: string;
  louvre: string;
  notes: string;
  img: string;
}

export class Shutter implements IShutter
{
  id: string;
  location: string;
  width: string;
  height: string;
  tilt: string;
  style: string;
  louvre: string;
  notes: string;
  img: string;
  /**
   *
   */
  constructor() {
    this.id = Utils.newGuid();
   // super();
  }
}
