import { Injectable } from '@angular/core';
import { IShutter, IShutterQuote, ShutterQuote } from '../models/IShutter';
import { HttpClient } from '@angular/common/http';
import { Contact, IContact } from '../models/IContact';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  shutterKey = 'ShutterQuote';

  constructor(private http: HttpClient) { }

  AddShutter(shutter: IShutter) {
    const existing = this.GetShutters();
    existing.shutters.push(shutter);
    localStorage.setItem(this.shutterKey, JSON.stringify(existing));
  }

  RemoveShutter(shutter: IShutter) {
    const existing = this.GetShutters();
    const index = existing.shutters.findIndex(w => w.id === shutter.id);

    if (index > -1) {
      existing.shutters.splice(index, 1);
    }

    localStorage.setItem(this.shutterKey, JSON.stringify(existing));
  }

  GetShutters(): IShutterQuote {
    const quoteJSON = localStorage.getItem(this.shutterKey);
    if (quoteJSON === null) {
      return new ShutterQuote();
    }
    return JSON.parse(quoteJSON) as IShutterQuote;
  }

  GetContactDetails(): IShutterQuote {
    const quoteJson = localStorage.getItem(this.shutterKey);
    const quote = JSON.parse(quoteJson) as IShutterQuote;
    if (quote.contact === undefined) {
      quote.contact = new Contact();
    }
    return quote;
  }

  SetContactDetails(contact: IContact) {
    const quoteJson = localStorage.getItem(this.shutterKey);
    const quote = JSON.parse(quoteJson) as IShutterQuote;
    quote.contact = contact;
    quote.notes = contact.desc;
    localStorage.setItem(this.shutterKey, JSON.stringify(quote));
  }

  SetImageDetails(shutter: IShutter) {
    const quoteJson = localStorage.getItem(this.shutterKey);
    const quote = JSON.parse(quoteJson) as IShutterQuote;
    const index = quote.shutters.findIndex(w => w.id === shutter.id);
    quote.shutters[index].img = shutter.img;
    localStorage.setItem(this.shutterKey, JSON.stringify(quote));
  }

  SendQuote() {
    const quoteJson = localStorage.getItem(this.shutterKey);
    const quote = JSON.parse(quoteJson) as IShutterQuote;
    quote.template = 'Phoenix-Shutter-Quote';
    return this.http.post<string>('https://8j3gl0zm1c.execute-api.eu-west-2.amazonaws.com/default/PhoenixContactForm',
      JSON.stringify(quote) );
  }
}
